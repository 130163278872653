import * as React from "react"
import { PhoneFrame } from "./atoms/phoneFrame"
const Wrapper = ({ children }) => {
  return (
    <div className="relative lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6  isolate min-h-screen overflow-x-scroll no-scrollbar">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-bermuda/30 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-background">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
        />
      </svg>
      <div className="-mx-4 h-[448px] px-9 sm:mx-0 lg:absolute lg:-inset-x-10 lg:h-auto lg:px-0">
        <PhoneFrame className="mx-auto max-w-[366px]" priority>
          {children}
        </PhoneFrame>
      </div>
    </div>
  )
}

export default Wrapper
